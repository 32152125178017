'use client'
import Link from "next/link"
import {Autoplay, Navigation, Pagination} from "swiper/modules"
import {Swiper, SwiperSlide} from "swiper/react"
import { useEffect, useState } from 'react'
import Accordion1 from "@/components/elements/Accordion1";

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 1,
    loop: false,
    spaceBetween: 0,
    navigation: {
        nextEl: ".btn-next-main",
        prevEl: ".btn-prev-main",
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
    },
    breakpoints: {
        1280: {
            slidesPerView: 1,
            spaceBetween: 0,
        },
    },
}

export default function Pagetitle1() {

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 991)
        }

        // Initial check
        handleResize()

        // Event listener for window resize
        window.addEventListener('resize', handleResize)

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <>

            <section className="page-title">
                <div className="container ">
                    <div className="row">

                        <div className="col-md-12">

                            <div className="tf-title" data-aos="fade-up" data-aos-duration={800}>

                                {/*<div className="icon_bg">*/}
                                {/*    <img src="/assets/images/backgroup/bg_inner_slider.png" alt=""/>*/}
                                {/*</div>*/}
                                <div className="swiper-container slider-main mb-40">
                                    <Swiper {...swiperOptions} className="swiper-wrapper">
                                        <SwiperSlide>
                                            <div className="slider-st1">

                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="box-slider">
                                                                <div className="content-box">
                                                                    <h1 className="title">Petlandtic</h1>
                                                                    <p className="sub-title">Step into Petlandtic, the
                                                                        groundbreaking <span>Play-to-Earn</span> game
                                                                        where adventure
                                                                        and <span>Meme Coin</span> rewards await you at
                                                                        every turn. As a premier pet universe on the TON
                                                                        Blockchain, Petlandtic offers an unparalleled
                                                                        blend of immersive gameplay, captivating
                                                                        characters, and a dynamic environment powered by
                                                                        state-of-the-art blockchain and Artificial Intelligence technologies.
                                                                        Experience a rich, interactive world where your
                                                                        skills are rewarded, and every adventure brings
                                                                        new opportunities.</p>
                                                                </div>
                                                                {isMobile ? (
                                                                    <>
                                                                        <div className="main-section image icon icon2">
                                                                            <img
                                                                                src="assets/images/logo/petlandtic-world-200.png"
                                                                                alt=""/>
                                                                            <div className="icon icon1">
                                                                                <img
                                                                                    src="assets/images/logo/petlandtic-coin-125.png"
                                                                                    alt=""/>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="main-section image icon icon2">
                                                                        <img
                                                                            src="assets/images/logo/petlandtic-world-500.png"
                                                                            alt=""/>
                                                                        <div className="icon icon1">
                                                                            <img
                                                                                src="assets/images/logo/petlandtic-coin-300.png"
                                                                                alt=""/>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>

                                </div>

                                <div className="col-md-12 mb-8">
                                    <br />
                                    <p className='sub-title'>Be the First to Know! Join our Telegram Channel for Launch Updates on Petlandtic! 🚀</p>
                                    <div className="wrap-btn">
                                        <Link href="https://t.me/petlandtic" className="tf-button style3 items-center">
                                            <i className="bi bi-telegram">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                     fill="currentColor" className="bi bi-telegram" viewBox="0 0 16 16">
                                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09"/>
                                                </svg>
                                            </i> Join Petlandtic
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
