'use client'
import Link from "next/link"
import {useEffect, useState} from 'react'
import Menu from "../Menu"
import MobileMenu from '../MobileMenu'
import ConsentBanner from '../../elements/ConsentBanner';

export default function Header1({scroll, isMobileMenu, handleMobileMenu, handleConnect}) {
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 991)
        }

        // Initial check
        handleResize()

        // Event listener for window resize
        window.addEventListener('resize', handleResize)

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    useEffect(() => {
        // Adding Google Analytics script dynamically
        const script = document.createElement('script')
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-79B2M2KX50"
        script.async = true
        document.head.appendChild(script)

        const inlineScript = document.createElement('script')
        inlineScript.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-79B2M2KX50');
        `
        document.head.appendChild(inlineScript)
    }, [])

    return (
        <>

            <header id="header_main" className={`header ${scroll ? "is-fixed is-small" : ""}`}>
                <div className="container">
                    <div id="site-header-inner">
                        <div className="header__logo">
                            <Link href="/"><img src="assets/images/logo/logo.png" alt=""/></Link>
                        </div>
                        {isMobile ? (
                            <MobileMenu isMobileMenu={isMobileMenu}/>
                        ) : (
                            <Menu/>
                        )}
                        {/*<a onClick={handleConnect} data-toggle="modal" data-target="#popup_bid"*/}
                        {/*   className="tf-button style2">*/}
                        {/*    Connect*/}
                        {/*</a>*/}
                        <div className={`mobile-button ${isMobileMenu ? "active" : ""}`} onClick={handleMobileMenu}>
                            <span/>
                        </div>
                    </div>
                </div>
            </header>
            <ConsentBanner />
        </>
    )
}
