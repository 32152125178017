'use client'
import {Autoplay, Navigation, Pagination} from "swiper/modules"
import {Swiper, SwiperSlide} from "swiper/react"
import Link from "next/link";

const swiperOptions = {
    modules: [Autoplay, Pagination, Navigation],
    slidesPerView: 1,
    loop: false,
    spaceBetween: 30,
    navigation: {
        clickable: true,
        nextEl: ".next_slider-7",
        prevEl: ".prev_slider-7",
    },
    breakpoints: {
        0: {
            slidesPerView: 1,
            spaceBetween: 30,
        },
        600: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        991: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
        1280: {
            slidesPerView: 6,
            spaceBetween: 30,
        },
    },
}

export default function Roadmap() {
    return (
        <>

            <section className="tf-section roadmap" id='roadmap'>
                <div className="container w_1850">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="tf-title" data-aos="fade-up" data-aos-duration={800}>
                                <h2 className="title">
                                    Roadmap
                                </h2>
                            </div>
                        </div>
                        <div>
                            <section className="tf-section roadmap">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="roadmap-wrapper-style2" data-aos="fade-up"
                                                 data-aos-duration={1200}>
                                                <div className="left">
                                                    <div className="roadmap-box-style active">
                                                        <div className="icon">
                                                            <img src="/assets/images/common/down.svg" alt=""/>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="date">April 16, 2022</h6>
                                                            <ul>
                                                                <li>Cras molestie ullamcorper augue nec pulvinar</li>
                                                                <li>Nam mollis sapien ut sapien gravida sollicitudin
                                                                </li>
                                                                <li>Mauris vel nisl quis dolor accumsan luctus</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="roadmap-box-style active">
                                                        <div className="icon">
                                                            <img src="/assets/images/common/down.svg" alt=""/>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="date">April 16, 2022</h6>
                                                            <ul>
                                                                <li>Cras molestie ullamcorper augue nec pulvinar</li>
                                                                <li>Nam mollis sapien ut sapien gravida sollicitudin
                                                                </li>
                                                                <li>Mauris vel nisl quis dolor accumsan luctus</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="roadmap-box-style">
                                                        <div className="icon">
                                                            <img src="/assets/images/common/down.svg" alt=""/>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="date">April 16, 2022</h6>
                                                            <ul>
                                                                <li>Cras molestie ullamcorper augue nec pulvinar</li>
                                                                <li>Nam mollis sapien ut sapien gravida sollicitudin
                                                                </li>
                                                                <li>Mauris vel nisl quis dolor accumsan luctus</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="roadmap-box-style">
                                                        <div className="icon">
                                                            <img src="/assets/images/common/down.svg" alt=""/>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="date">April 16, 2022</h6>
                                                            <ul>
                                                                <li>Cras molestie ullamcorper augue nec pulvinar</li>
                                                                <li>Nam mollis sapien ut sapien gravida sollicitudin
                                                                </li>
                                                                <li>Mauris vel nisl quis dolor accumsan luctus</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="roadmap-box-style active">
                                                        <div className="icon">
                                                            <img src="/assets/images/common/down.svg" alt=""/>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="date">April 16, 2022</h6>
                                                            <ul>
                                                                <li>Cras molestie ullamcorper augue nec pulvinar</li>
                                                                <li>Nam mollis sapien ut sapien gravida sollicitudin
                                                                </li>
                                                                <li>Mauris vel nisl quis dolor accumsan luctus</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="roadmap-box-style active">
                                                        <div className="icon">
                                                            <img src="/assets/images/common/down.svg" alt=""/>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="date">April 16, 2022</h6>
                                                            <ul>
                                                                <li>Cras molestie ullamcorper augue nec pulvinar</li>
                                                                <li>Nam mollis sapien ut sapien gravida sollicitudin
                                                                </li>
                                                                <li>Mauris vel nisl quis dolor accumsan luctus</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="roadmap-box-style">
                                                        <div className="icon">
                                                            <img src="/assets/images/common/down.svg" alt=""/>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="date">April 16, 2022</h6>
                                                            <ul>
                                                                <li>Cras molestie ullamcorper augue nec pulvinar</li>
                                                                <li>Nam mollis sapien ut sapien gravida sollicitudin
                                                                </li>
                                                                <li>Mauris vel nisl quis dolor accumsan luctus</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
