// 'use client'
//
// import Link from 'next/link'
// import { usePathname } from 'next/navigation'
// import { useEffect, useState } from 'react'
//
// export default function Menu() {
//     const pathname = usePathname()
//     const [currentMenuItem, setCurrentMenuItem] = useState("")
//
//     useEffect(() => {
//         setCurrentMenuItem(pathname)
//     }, [pathname])
//
//     const checkCurrentMenuItem = (path) => currentMenuItem === path ? "current-menu-item" : ""
//     const checkParentActive = (paths) => paths.some(path => currentMenuItem.startsWith(path)) ? "current-menu-item" : ""
//
//     return (
//         <>
//         <nav id="main-nav" className="main-nav">
//             <ul id="menu-primary-menu" className="menu">
//                 <li className={`menu-item`}>
//                     <Link href="#">Home</Link>
//                     <Link href="#">Tokenomics</Link>
//                     <Link href="#">Roadmap</Link>
//                     <Link href="#">Social</Link>
//                 </li>
//             </ul>
//         </nav>
//         </>
//     )
// }
//

//Whitepaper

'use client'

import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

export default function Menu() {
    const pathname = usePathname()
    const [currentMenuItem, setCurrentMenuItem] = useState("")

    useEffect(() => {
        setCurrentMenuItem(pathname)
    }, [pathname])

    const checkCurrentMenuItem = (path) => currentMenuItem === path ? "current-menu-item" : ""
    const checkParentActive = (paths) => paths.some(path => currentMenuItem.startsWith(path)) ? "current-menu-item" : ""

    return (
        <nav id="main-nav" className="main-nav">
            <ul id="menu-primary-menu" className="menu">
                <li className={`menu-item`}>
                    <Link href="#">Home</Link>
                </li>
                {/*<li className={`menu-item`}>*/}
                {/*    <Link href="#roadmap">Roadmap</Link>*/}
                {/*</li>*/}
                {/*<li className={`menu-item`}>*/}
                {/*    <Link href="#tokenomics">Tokenomics</Link>*/}
                {/*</li>*/}
                {/*<li className={`menu-item`}>*/}
                {/*    <Link href="#faq">FAQs</Link>*/}
                {/*</li>*/}
                {/*<li className={`menu-item ${pathname === "/contact" ? "current-menu-item" : ""}`}>*/}
                {/*    <Link href="/contact">Contact</Link>*/}
                {/*</li>*/}
            </ul>
        </nav>
    )
}
