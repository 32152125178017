import { useEffect, useState } from 'react';

export default function ConsentBanner() {
    const [isConsentGiven, setIsConsentGiven] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('consentGranted');
        if (consent === 'true') {
            setIsConsentGiven(true);
        }
    }, []);

    const handleConsent = () => {
        localStorage.setItem('consentGranted', 'true');
        setIsConsentGiven(true);

        function gtag() { dataLayer.push(arguments); }

        gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted'
        });

        // Load gtag.js script.
        var gtagScript = document.createElement('script');
        gtagScript.async = true;
        gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-79B2M2KX50';

        var firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(gtagScript, firstScript);
    };

    if (isConsentGiven) {
        return null;
    }

    return (
        <div className="consent-banner">
            <p>We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.</p>
            <button onClick={handleConsent}>Grant Consent</button>
            <style jsx>{`
                .consent-banner {
                    font-family: 'Planet-Joy';
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    background: rgba(0, 0, 0, 0.8);
                    color: #fff;
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    z-index: 1000;
                }
                .consent-banner p {
                    margin: 0;
                }
                .consent-banner button {
                    height: 52px;
                    padding: 16px 30px;
                    font-family: 'Planet-Joy';
                    background-size: 200% 100%;
                    background-position: 0% 0%;
                    border-radius: 8px;
                    font-size: 14px;
                    line-height: 33px;
                    text-transform: uppercase;
                    background: #86FF00;
                    color: black;
                    border: none;
                    padding: 10px 20px;
                    cursor: pointer;
                    font-size: 16px;
                }
            `}</style>
        </div>
    );
}
